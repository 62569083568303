#survey .inner{
    width:600px;
    max-width: 100%;
    margin: auto;
    height:100%;
}

#survey .inner .m-title{
    color: #710493;
    text-align: center;
    font-weight: bold;
    text-align: center;
}
.contact-container{
    background: #fff;
    padding: 10px;
    margin-bottom: 10px;
}

.contact{
    display: flex;
    height: 40px;
    align-items: center;
}

.contact-title h4{
    color: #710493;
    font-weight: bold;
    font-size: 15px;
    float: left;
    margin-bottom: 0px !important;
}

.info-icon{
    position: absolute;
    right: 14px;
    font-size: 20px;
    color: #710493;
    font-weight: bold;
    z-index: 0;
}

.info-label{
    color: #000;
    font-weight: bold;
    margin-bottom: 2px;
    font-size: 15px;
}

.ifo-itms{
    padding-top: 20px;
    width: 90%;
    margin: auto;
}



.caption-text{
    color: #710493;
    text-align: center;
    font-weight: normal;
    background-color: rgba(255,255,255,0.5);
    padding: 10px 5px;
    
}

.terms{
    color: #710493;
    margin: 20px 10px;
    text-align: center;
    font-size: 10px;
}

.req{
    color: #f00;
}
.submit-button{
    display: block;
    margin: auto;
    border: none;
    color: #fff;
    background: #710493;
    border-radius: 50vw;
    width: 170px;
    font-weight: bold;
    padding: 10px;
    font-size: 20px;
}


.questionnaire .txt-area{
    background: #fff;
    font-weight: bold;
    padding-top: 10px;
}

.questionnaire .txt-area label{
    color: #710493;
    text-transform: uppercase;
    padding: 0px 10px;
    font-family: "gothic";
    font-weight: bold;
}

.questionnaire .txt-area textarea{
    border: none;
    color:#000;
    border-radius: 0;
}


.questionnaire .txt{
    background: #fff;
    font-weight: bold;
    padding-top: 10px;
}

.questionnaire .txt label{
    color: #710493;
    text-transform: uppercase;
    padding: 0px 10px;
    font-family: "gothic";
    font-weight: bold;
}

.questionnaire .txt input{
    border: none;
    color:#000;
    border-radius: 0;
}

.questionnaire .check{
    background: #fff;
    padding-top: 10px;
}

.questionnaire .check label{
    color: #710493;
    display: block;
    font-weight: bold;
    text-transform: uppercase;
    padding: 0px 10px;
    font-family: "gothic";
}

.questionnaire .check label.chklbl{
    font-weight: normal;
    color: #000;
    font-size: 12px;
    padding: 5px 10px;
}

.questionnaire .txt input{
    border: none;
    color:#000;
    border-radius: 0;
}

.questionnaire .q-radio label{
    color: #710493;
    font-weight: bold;
    text-align: center;
    display: block;
    background-color: #fff;
    padding: 10px;
}

.questionnaire .select{
    background-color: #fff;
}

.questionnaire .select label{
    padding: 10px;
    color: #710493;

}

.questionnaire .select select{
    border-radius: 0;
    border: 0;
}